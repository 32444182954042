import Joi from "joi";
import Graphs from "../Graphs";

/*******************
 * Default Values
 *******************/

export const validSelectedPeriods = ["hour", "shift", "production_day", "production_run"];
export const validSelectedKpis = [
  "availability",
  "in_progress_downtime",
  "oee",
  "performance",
  "product_giveaway",
  "product_giveaway_percent",
  "product_average_giveaway",
  "product_quantity",
  "product_throughput",
  "product_speed_5m",
  "total_uptime",
  "total_downtime",
  "work_order",
  "lot",
  "production",
  "quality",
  "reject_quantity",
  "cycle_quantity",
  "cycle_time",
  "ooe",
  "time_to_completion",
  "total_weight",
  "total_volume",
  "total_length",
];
export const validProductUnits = ["unit", "case", "pack", "bottle", "box", "cycle", "bag", "pallet"];
export const validWeightUnits = ["g", "kg", "oz", "lb", "mt", "t"];
export const validLengthUnits = ["m", "mm", "in", "ft", "mi", "km", "yd"];
export const validVolumeUnits = ["ml", "l", "hl", "fl-oz", "gal", "gal-uk"];
export const validWeightPuConvertedUnitName = ["kilogram", "ton", "pound", "ounce"];
export const validLengthPuConvertedUnitName = ["meter", "feet"];
export const validVolumePuConvertedUnitName = ["liter"];
export const validTimeUnits = ["hour", "minute", "second"];
export const validDashboardTabs = ["timeline", "giveaway"];
export const validDashboardGraphs = [
  Graphs.oeeTrend.name,
  Graphs.quantity.name,
  Graphs.weight.name,
  Graphs.volume.name,
  Graphs.length.name,
  Graphs.rejectQuantity.name,
  Graphs.rejectWeight.name,
  Graphs.rejectVolume.name,
  Graphs.rejectLength.name,
];
export const validProductQuantityScopes = ["current_product", "all_products"];

export const getUnitType = (unit) => {
  if (validProductUnits.indexOf(unit) >= 0) return "product_unit";
  if (validWeightUnits.indexOf(unit) >= 0 || validWeightPuConvertedUnitName.indexOf(unit) > 0) return "weight_unit";
  if (validLengthUnits.indexOf(unit) >= 0 || validLengthPuConvertedUnitName.indexOf(unit) > 0) return "length_unit";
  if (validVolumeUnits.indexOf(unit) >= 0 || validVolumePuConvertedUnitName.indexOf(unit) > 0) return "volume_unit";
};

/*
{
  language: "",
  theme: "",
  selected_period: "",
  overview: {
    selected_kpi: "",
    product_unit: "",
    time_unit: "",
    weight_unit: "",
    length_unit: "",
    volume_unit: "",
  }
  dashboards: {
    selected_production_unit_id: "",
    production_units: [
      {
        graph: {
          selected_kpi: "",
          config: {}
        },
        id: "",
        tab: "",
        tiles: [
          {
            tile: "availability",
            config: {},
          },
          {
            tile: "product_quantity",
            config: {
              scope: "",
              product_unit: "",
            },
            ...
          }
        ]
      },
      ...
    ]
  }
}
*/

/*****************************
 * Joi Schema Helper Functions
 *****************************/

/**
 * This function takes in a list of valid entries and a default value, and will return
 * a Joi schema that will validate a property against the valid entries. If the property
 * isn't set or is not a valid entry, it will set the default value instead of throwing an error.
 *
 * @param validEntries
 * @param defaultValue
 * @returns {Joi.StringSchema<string>}
 */
const getValidStringOrDefault = (validEntries, defaultValue = null) => {
  return Joi.string()
    .empty(Joi.not(...validEntries))
    .default(defaultValue);
};

/**
 * This function takes a default value and returns a Joi schema which will validate that a property
 * is an array. If it is null or undefined, it will return the default value.
 *
 * @param defaultValue
 * @param max
 * @returns {Joi.ArraySchema<any[]>}
 */
const getValidArrayOrDefault = (defaultValue = [], max) => {
  let schema = Joi.array().required().min(defaultValue.length)
  if (max) {
    schema = schema.max(max);
  }
  return Joi.alternatives()
    .try(
      schema, // first tries to validate that it's an array
      Joi.any().empty(Joi.any()), // if first validation fails (not an array), this sets it to empty which will set the default value
    )
    .default(defaultValue);
};

/**
 * This function takes in a schema for an object, and returns a Joi schema that will validate a property against
 * the schema provided. If the property is null or undefined, it will return the default schema.
 *
 * It also takes in options for the object:
 *    - allowUnknown: allows properties that are not defined in the schema instead of throwing an error
 *    - stripUnknown: will remove properties from the object that are not defined in the schema
 *
 * @param schema
 * @param options
 * @returns {Joi.ObjectSchema<any>}
 */
const getValidObjectOrDefault = (schema, options = { allowUnknown: true, stripUnknown: false }) => {
  // get the default schema in the scenario where the property is null or undefined.
  const defaultValue = Joi.object()
    .keys(schema)
    .validate({}).value;

  return Joi.alternatives()
    .try(
      Joi.object().keys(schema).required(), // tries the schema validation first
      Joi.any().empty(Joi.any()), // if first validation fails, the second will always set it to empty which will set the default value
    )
    .default(defaultValue)
    .options(options);
};

/**
 * This function returns a Joi validation schema that will always set the property to null.
 *
 * @returns {Joi.StringSchema<string>}
 */
const stringMustBeNull = () => {
  return Joi.any()
    .empty(Joi.any())
    .default(null);
};

/*****************************
 * Default Joi Schemas
 *
 * Functions that return a Joi schema to be used for validation. These schemas will validate and return a default
 * json if the input is null/undefined/empty.
 *****************************/

/**
 * Function that returns the schema for the top level preferences to be used for validation. If this schema is used
 * to validate a null, undefined or empty json it will return the default preferences.
 *
 * @param authorizedPuIds
 * @returns {Joi.ObjectSchema<any>}
 */
const getDefaultValidPreferencesValidation = (authorizedPuIds) => {
  const schema = {
    language: getValidStringOrDefault(["en", "fr", "es"], "en"),
    theme: getValidStringOrDefault(["light", "dark", "accessible"], "dark"),
    selected_period: getValidStringOrDefault(validSelectedPeriods, "production_day"),
    overview: getValidObjectOrDefault(
      {
        selected_kpi: getValidStringOrDefault(validSelectedKpis, "oee"),
        scope: Joi.when("selected_kpi", {
          is: "product_quantity",
          then: getValidStringOrDefault(validProductQuantityScopes, "all_products"),
          otherwise: stringMustBeNull(),
        }),
        time_unit: Joi.when("selected_kpi", {
          switch: [
            { is: "product_throughput", then: getValidStringOrDefault(validTimeUnits, "hour") },
            { is: "product_speed_5m", then: getValidStringOrDefault(validTimeUnits, "hour") },
          ],
          otherwise: stringMustBeNull(),
        }),
        product_unit: Joi.when("selected_kpi", {
          switch: [
            { is: "product_throughput", then: getValidStringOrDefault(validProductUnits.concat(validWeightUnits, validLengthUnits, validVolumeUnits)) },
            { is: "product_speed_5m", then: getValidStringOrDefault(validProductUnits.concat(validWeightUnits, validLengthUnits, validVolumeUnits)) },
            { is: "total_weight", then: getValidStringOrDefault(validWeightUnits, "kg") },
            { is: "total_length", then: getValidStringOrDefault(validLengthUnits, "m") },
            { is: "total_volume", then: getValidStringOrDefault(validVolumeUnits, "l") },
            { is: "reject_quantity", then: getValidStringOrDefault(validWeightUnits.concat(validLengthUnits, validVolumeUnits)) }
          ],
          otherwise: stringMustBeNull(),
        }),
      },
      { allowUnknown: true, stripUnknown: true },
    ),
    dashboards: getValidObjectOrDefault({
      // default to the first authorized PU if the selected ID is not valid anymore
      selected_production_unit_id: getValidStringOrDefault(authorizedPuIds, authorizedPuIds[0] ?? null),
      production_units: getValidArrayOrDefault([]),
    }),
  };

  return getValidObjectOrDefault(schema);
};

/**
 * Function that returns the schema for the dashboard level preferences to be used for validation. If this schema is used
 * to validate a null, undefined or empty json it will return the default preferences.
 *
 * @param productionUnitId
 * @returns {Joi.ObjectSchema<any>}
 */
const getDefaultValidDashboardPreferencesValidation = (productionUnitId) => {
  const schema = {
    id: getValidStringOrDefault([productionUnitId], productionUnitId),
    tab: getValidStringOrDefault(validDashboardTabs, "timeline"),
    graph: getValidObjectOrDefault({
      selected_kpi: getValidStringOrDefault(validDashboardGraphs, Graphs.quantity.name),
      config: {
        product_unit: "unit",
      },
    }),
    tiles: getValidArrayOrDefault(
      [
        { tile: "oee", config: {} },
        { tile: "availability", config: {} },
        { tile: "performance", config: {} },
        { tile: "quality", config: {} },
        { tile: "in_progress_downtime", config: {} },
      ],
      5,
    ),
  };

  return getValidObjectOrDefault(schema);
};

/**********************************
 * Tile Config Validation Schemas
 *
 * Some tile configs need a specific validation. Create them here and add them
 * to the switch array below.
 **********************************/

const productQuantityConfigValidationSchema = {
  scope: getValidStringOrDefault(validProductQuantityScopes, "all_products"),
  product_unit: getValidStringOrDefault(validProductUnits),
};
const productThroughputConfigValidationSchema = {
  time_unit: getValidStringOrDefault(validTimeUnits, "hour"),
  product_unit: getValidStringOrDefault(validProductUnits.concat(validWeightUnits, validLengthUnits, validVolumeUnits)),
};
const productSpeed5mConfigValidationSchema = {
  time_unit: getValidStringOrDefault(validTimeUnits, "hour"),
  product_unit: getValidStringOrDefault(validProductUnits.concat(validWeightUnits, validLengthUnits, validVolumeUnits)),
};
const rejectQuantityConfigValidationSchema = {
  product_unit: getValidStringOrDefault(validProductUnits.concat(validWeightUnits, validLengthUnits, validVolumeUnits)),
};
const totalWeightConfigValidationSchema = {
  product_unit: getValidStringOrDefault(validWeightUnits, "kg"),
};
const totalLengthConfigValidationSchema = {
  product_unit: getValidStringOrDefault(validLengthUnits, "m"),
};
const totalVolumeConfigValidationSchema = {
  product_unit: getValidStringOrDefault(validVolumeUnits, "l"),
};

const getConfigValidation = (tileName) => {
  switch (tileName) {
    case "product_quantity":
      return productQuantityConfigValidationSchema;
    case "product_throughput":
      return productThroughputConfigValidationSchema;
    case "product_speed_5m":
      return productSpeed5mConfigValidationSchema;
    case "reject_quantity":
      return rejectQuantityConfigValidationSchema;
    case "total_weight":
      return totalWeightConfigValidationSchema;
    case "total_length":
      return totalLengthConfigValidationSchema;
    case "total_volume":
      return totalVolumeConfigValidationSchema;
    default:
      return {};
  }
};

const quantityGraphConfigValidationSchema = {
  product_unit: getValidStringOrDefault(validProductUnits, null),
};

/***********************************
 * Validate and Transform Functions
 ***********************************/

const transformOldTilePreference = (tilePreference) => {
  // check if there is a tile preference, if not default to availability
  if (!tilePreference || tilePreference === {}) return {};
  // check if the tile preference has the new structure
  const results = Joi.object()
    .keys({
      tile: Joi.string().required(),
      config: Joi.object().required(),
    })
    .validate(tilePreference);
  // if no errors, it means it has the new structure so no need to transform
  if (!results.error) return tilePreference;
  // get the keys from the object to find the tile name and its config
  const keys = Object.keys(tilePreference);
  if (keys.length !== 2) return {}; // tile is not constructed properly, return an empty object which will default to availability
  const tileName = keys.find((k) => k !== "position");
  const tileConfig = tilePreference[tileName];
  if ((tileName === "product_throughput" || tileName === "product_speed_5m") && tileConfig.unit === "units_per_hour") {
    tileConfig.time_unit = "hour";
  } else if ((tileName === "product_throughput" || tileName === "product_speed_5m") && tileConfig.unit === "units_per_minute") {
    tileConfig.time_unit = "minute";
  } else if ((tileName === "product_throughput" || tileName === "product_speed_5m") && tileConfig.unit === "units_per_second") {
    tileConfig.time_unit = "second";
  }
  // return the tile preference with the new structure
  return {
    tile: tileName,
    config: Joi.object(getConfigValidation(tileName)).validate(tileConfig, { stripUnknown: true }).value,
  };
};

const validateAndTransformTilePreferences = (tilePreference) => {
  const schema = {
    tile: getValidStringOrDefault(validSelectedKpis, "availability"),
    config: Joi.when("tile", {
      switch: [
        { is: "product_quantity", then: getValidObjectOrDefault(productQuantityConfigValidationSchema) },
        { is: "product_throughput", then: getValidObjectOrDefault(productThroughputConfigValidationSchema) },
        { is: "product_speed_5m", then: getValidObjectOrDefault(productSpeed5mConfigValidationSchema) },
        { is: "reject_quantity", then: getValidObjectOrDefault(rejectQuantityConfigValidationSchema) },
        { is: "total_weight", then: getValidObjectOrDefault(totalWeightConfigValidationSchema) },
        { is: "total_length", then: getValidObjectOrDefault(totalLengthConfigValidationSchema) },
        { is: "total_volume", then: getValidObjectOrDefault(totalVolumeConfigValidationSchema) },
      ],
      otherwise: getValidObjectOrDefault({}),
    }),
  };

  return getValidObjectOrDefault(schema).validate(tilePreference).value;
};

const transformOldGraphPreference = (graphPreference) => {
  if (!graphPreference || graphPreference === {}) return {};

  if (graphPreference === "oee") {
    return {
      selected_kpi: Graphs.oeeTrend.name,
    };
  } else if (graphPreference === "quantity") {
    return {
      selected_kpi: Graphs.quantity.name,
      config: {
        product_unit: null,
      },
    };
  } else {
    return graphPreference;
  }
};

const validateAndTransformGraphPreferences = (graphPreference) => {
  const schema = {
    selected_kpi: getValidStringOrDefault(validDashboardGraphs, "quantity"),
    config: Joi.when("selected_kpi", {
      switch: [
        { is: Graphs.oeeTrend.name, then: getValidObjectOrDefault({}) },
        { is: Graphs.quantity.name, then: getValidObjectOrDefault(quantityGraphConfigValidationSchema) },
        { is: Graphs.weight.name, then: getValidObjectOrDefault(totalWeightConfigValidationSchema) },
        { is: Graphs.volume.name, then: getValidObjectOrDefault(totalVolumeConfigValidationSchema) },
        { is: Graphs.length.name, then: getValidObjectOrDefault(totalLengthConfigValidationSchema) },
        { is: Graphs.rejectQuantity.name, then: getValidObjectOrDefault(quantityGraphConfigValidationSchema) },
        { is: Graphs.rejectWeight.name, then: getValidObjectOrDefault(totalWeightConfigValidationSchema) },
        { is: Graphs.rejectVolume.name, then: getValidObjectOrDefault(totalVolumeConfigValidationSchema) },
        { is: Graphs.rejectLength.name, then: getValidObjectOrDefault(totalLengthConfigValidationSchema) },
      ],
      otherwise: getValidObjectOrDefault({}),
    }),
  };
  return getValidObjectOrDefault(schema).validate(graphPreference).value;
};

const validateAndTransformPuDashboardPreferences = (puDashboardPreferences, productionUnitId) => {
  // step 1: validate the top level preferences
  const topLevelSchema = getDefaultValidDashboardPreferencesValidation(productionUnitId);
  const validatedTopLevelPreferences = topLevelSchema.validate(puDashboardPreferences ?? {}).value;
  // step 2: validate tile preferences one by one
  const tiles = [];
  for (let i = 0; i < validatedTopLevelPreferences.tiles.length; i++) {
    const tilePreference = validatedTopLevelPreferences.tiles[i];
    // temporary function to transform a tile with the old structure to the new
    const transformedTilePreference = transformOldTilePreference(tilePreference);
    const validatedAndTransformedTile = validateAndTransformTilePreferences(transformedTilePreference);
    tiles.push(validatedAndTransformedTile);
  }
  // set the validated tiles back into the pu dashboard preferences
  validatedTopLevelPreferences.tiles = tiles;
  // step 3: validate the Graph preferences
  const transformedGraphPreference = transformOldGraphPreference(puDashboardPreferences.graph);
  validatedTopLevelPreferences.graph = validateAndTransformGraphPreferences(transformedGraphPreference);
  // return the validated preferences
  return validatedTopLevelPreferences;
};

/**
 * This function takes the preferences from the backend API and the user authorized production unit ids from the JWT token as paremeters.
 * It then validates the preferences against a Joi schema that will set default values to any property that is invalid, required and/or undefined.
 *
 * The authorized pu IDs are used to make sure the user always has a dashboard preference for each authorized production unit. If a production
 * unit gets deleted, it will be removed from the preferences on the next load. If a production unit gets added, it will be added to the
 * preferences on the next load.
 *
 * Only the preferences used in Tileplus are validated. Preferences used for Tilelytics will be saved but not validated, that is done in the
 * Tilelytics UI repository. This way when a change is needed we'll most likely only need to update one repo instead of both, unless the
 * property is used in both UIs.
 *
 * @param preferences
 * @param authorizedPuIds
 */
export function validateAndTransformUserPreferences(preferences, authorizedPuIds) {
  // step 1: validate the top level preferences
  const topLevelSchema = getDefaultValidPreferencesValidation(authorizedPuIds);
  const results = topLevelSchema.validate(preferences ?? {});
  const validatedTopLevelPreferences = results.value;
  // step 2: validate the production unit dashboards one by one
  const authorizedPuDashboards = [];
  authorizedPuIds.forEach((puId) => {
    // find the preferences for the pu
    let puDashboardPreferences = validatedTopLevelPreferences.dashboards.production_units.find(
      (puDashboard) => puDashboard.id === puId,
    );
    if (!puDashboardPreferences) {
      const puDashboardPreferencesSchema = getDefaultValidDashboardPreferencesValidation(puId);
      puDashboardPreferences = puDashboardPreferencesSchema.validate(puDashboardPreferences ?? {}).value;
    }
    // validate preferences. if no dashboard existed for this pu, Joi.validate() will return a default one
    const validatedPuDashboardPreferences = validateAndTransformPuDashboardPreferences(puDashboardPreferences, puId);
    // push the validated dashboard preferences to the authorized pu dashboard list
    authorizedPuDashboards.push(validatedPuDashboardPreferences);
  });
  // step 3: set the validated and transformed dashboards back into the preferences. preferences for PUs no longer authorized
  //         will not be added back and will essentially get discarded.
  validatedTopLevelPreferences.dashboards.production_units = authorizedPuDashboards;
  return validatedTopLevelPreferences;
}
