import convert from "convert-units";
import i18n from "@/i18n";
import Helpers, { dash } from "@/helpers";

export function convertKilogramTo(value, targetUnit) {
  if (isNaN(value)) return null;
  // convert supports all our weight units
  const convertedValue = convert(value)
    .from("kg")
    .to(targetUnit);

  return Helpers.round(convertedValue, 1);
}

export function convertLiterTo(value, targetUnit) {
  if (isNaN(value)) return null;
  // convert doesn't support imperial gallons and hectoliters
  let convertedValue;
  if (targetUnit === "gal-uk") {
    convertedValue = 0.219969 * value;
  } else if (targetUnit === "hl") {
    convertedValue = 0.01 * value;
  } else {
    convertedValue = convert(value)
      .from("l")
      .to(targetUnit);
  }

  return Helpers.round(convertedValue, 1);
}

export function convertMeterTo(value, targetUnit) {
  if (isNaN(value)) return null;
  // convert supports all our length units
  const convertedValue = convert(value)
    .from("m")
    .to(targetUnit);

  return Helpers.round(convertedValue, 1);
}

export function convertWeight(from, to, value) {
  if (isNaN(value)) return null;
  return convert(value)
    .from(from)
    .to(to);
}

export function convertVolume(from, to, value) {
  if (isNaN(value)) return null;
  if (from === to) return value; // return the same value if the same unit
  if (from === "gal-uk") {
    // convert value to "l" then convert to unit
    return convertLiterTo(value * 4.54609);
  }
  if (from === "hl") {
    // convert value to "l" then convert to unit
    return convertLiterTo(value * 100.0, to);
  }
  if (to === "gal-uk" || to === "hl") {
    // convert value to "l" then convert to unit
    const valueInLiter = convert(value).from(from).to("l")
    return convertLiterTo(valueInLiter, to);
  }
  return convert(value)
    .from(from)
    .to(to);
}

export function convertLength(from, to, value) {
  if (isNaN(value)) return null;
  return convert(value)
    .from(from)
    .to(to);
}

export const getUnitLabel = (unit) => {
  switch (unit) {
    case "kg":
      return i18n.t("common.units.kilogram");
    case "g":
      return i18n.t("common.units.gram");
    case "mt":
      return i18n.t("common.units.ton");
    case "t":
      return i18n.t("common.units.imperialTon");
    case "lb":
      return i18n.t("common.units.pound");
    case "oz":
      return i18n.t("common.units.ounce");
    case "l":
      return i18n.t("common.units.liter");
    case "ml":
      return i18n.t("common.units.milliliter");
    case "hl":
      return i18n.t("common.units.hectoliter");
    case "fl-oz":
      return i18n.t("common.units.fluidOunce");
    case "gal":
      return i18n.t("common.units.gallon");
    case "gal-uk":
      return i18n.t("common.units.imperialGallon");
    case "m":
      return i18n.t("common.units.meter");
    case "mm":
      return i18n.t("common.units.millimeter");
    case "km":
      return i18n.t("common.units.kilometer");
    case "in":
      return i18n.t("common.units.inch");
    case "yd":
      return i18n.t("common.units.yard");
    case "ft":
      return i18n.t("common.units.feet");
    case "mi":
      return i18n.t("common.units.mile");
    default:
      return dash;
  }
};
